import { Box, HStack, Spacer } from 'native-base';
import { Button, Text, TextProps } from '@pimm/base';

type KitchenMenuOptionProps = {
  _button?: React.ComponentProps<typeof Button>;
  _container?: React.ComponentProps<typeof HStack>;
  _text?: TextProps;
  count?: number;
  title: string;
  textButton?: string;
  onPress?: () => void;
};

export const KitchenMenuOption = ({ _button, _container, _text, count, textButton, title, ...props }: KitchenMenuOptionProps) => {
  return (
    <HStack space={2} rounded="lg" alignItems="center" px={2} minW="110px" minH="44px" bgColor="gray.300" {..._container}>
      <Box alignItems="center" minW={6}>
        <Text size={{ md: '6xl', xl: '8xl' }} fontWeight={700} color="black" {..._text}>
          {count ?? 0}
        </Text>
      </Box>

      <Box flex={1}>
        <Text size={{ md: 'sm', xl: 'md' }} fontWeight={700} lineHeight="2xs" color="black" {..._text}>
          {title}
        </Text>
      </Box>

      <Button
        alternate
        outline
        py={0}
        px={2.5}
        minH={{ md: 6, xl: 7 }}
        bg="white"
        borderWidth={0}
        _hover={{ bg: 'white' }}
        _pressed={{ bg: 'gray.25' }}
        _text={{ color: 'gray.700' }}
        onPress={props.onPress}
        {..._button}
      >
        {textButton ?? 'View'}
      </Button>
    </HStack>
  );
};
