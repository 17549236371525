import { decode } from 'html-entities';
import { startCase, toLower } from 'lodash';

import { useAppGlobalsGoals } from '@app/features/app';
import { TemperatureUnitEnum } from '@pimm/services/lib/sms-tenants';

export const formatName = (firstName?: string, lastName?: string): string => {
  return startCase(toLower([firstName, lastName].filter(Boolean).join(' ')));
};

export const getTempUnit = (): string => {
  const unitSymbol = useAppGlobalsGoals.getState().temperatureUnit === TemperatureUnitEnum.Celsius ? 'C' : 'F';
  return `${decode('&deg;')}${unitSymbol}`;
};

export const hexToRGBA = (hex?: string, opacity?: number): string | undefined => {
  if (typeof hex !== 'string' || !hex.match(/^#[0-9A-Fa-f]{6}$/)) return undefined;
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity ?? 1})`;
};

export const hideEmail = (email: string): string => {
  return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

export const tempValue = (value?: number | string, includeUnit: boolean = true): string => {
  const temp: number = isNaN(Number(value)) ? 0 : Number(value);
  return `${temp.toFixed(1)}${includeUnit ? `${getTempUnit()}` : ''}`;
};
