import React, { useEffect, useMemo, useState } from 'react';
import { Pressable } from 'react-native';
import { VStack, HStack, View, useMediaQuery, IconButton } from 'native-base';
import { useBoolean } from 'usehooks-ts';
import { map } from 'lodash';

import { ButtonGroup, LightBulbIcon, Text } from '@pimm/base';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import { MainScreenProps } from '@app/navigations/root';
import { MainContainer } from '@app/components/core';
import { ScreenLayout } from '@app/components/layout';
import { ModuleTitle } from '@app/features/app';
import CalendarEvents, {
  BadgeCalendarEvent,
  CalendarDayPicker,
  CalendarEventType,
  FilterToolEvents,
  useCalendarEvents,
} from '@app/features/calendar';
import { isSameMonthDate } from '@app/features/calendar/_helper';
import { ModalAppDocuments } from '@app/features/store-info/components';

export type CalendarScreenProps = MainScreenProps<'Calendar'>;

export const CalendarScreen: React.FC<CalendarScreenProps> = ({ route }) => {
  const [isSmallScreen] = useMediaQuery({
    maxWidth: 1024,
  });
  const isWeekly = useBoolean();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const { events: allEvents } = useCalendarEvents();
  const [eventTypes, setEventTypes] = useState<CalendarEventType[]>();
  const [todayDate, setTodayDate] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date>();

  const eventsForToday = useMemo(() => {
    if (!todayDate) return [];
    const events = allEvents?.filter(event => eventTypes?.includes(event.type));
    return events.filter(event => isSameMonthDate(event.dateTime, todayDate));
  }, [allEvents, eventTypes, todayDate]);

  const allActiveEvents = useMemo(() => {
    return allEvents?.filter(event => eventTypes?.includes(event.type));
  }, [allEvents, eventTypes]);

  const onPressDay = (day: Date) => {
    if (day !== selectedDate) setSelectedDate(day);
  };

  useEffect(() => {
    if (siteConfig.id) {
      setTodayDate(siteTime.today());
      setSelectedDate(siteTime.today());
    }
  }, [siteConfig.id]);

  return (
    <MainContainer>
      <ScreenLayout
        title={
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Calendar" />
            <ModalAppDocuments
              identifierName="Module.Calendar"
              moduleType="Calendar"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>
        }
        actionTool={
          <HStack alignItems="center" justifyContent="flex-end" space={4}>
            <FilterToolEvents onChange={setEventTypes} />
            <ButtonGroup value={isWeekly.value} onChange={value => isWeekly.setValue(value as boolean)}>
              <ButtonGroup.Item value={true}>Week</ButtonGroup.Item>
              <ButtonGroup.Item value={false}>Month</ButtonGroup.Item>
            </ButtonGroup>
          </HStack>
        }
      >
        {!!todayDate && (
          <HStack flex={1} w="full" h="full">
            <VStack display={isSmallScreen ? 'none' : undefined} h="full">
              <View py={1} borderBottomWidth={1}>
                <CalendarDayPicker events={allEvents} onPressDay={onPressDay} todayDate={todayDate} />
              </View>
              <View flex={1} pt={3} px={3}>
                <Text mb={3} size="lg" color="gray.700" fontWeight={700}>
                  Today
                </Text>
                <VStack space={2}>
                  {map(eventsForToday, (event, index) => (
                    <Pressable key={index}>
                      <BadgeCalendarEvent date={todayDate} event={event} rounded="sm" height={6} />
                    </Pressable>
                  ))}
                </VStack>
              </View>
            </VStack>
            <View flex={1} h="full" borderLeftWidth={1}>
              <CalendarEvents _container={{ borderWidth: 0 }} date={selectedDate} events={allActiveEvents} weekly={isWeekly.value} />
            </View>
          </HStack>
        )}
      </ScreenLayout>
    </MainContainer>
  );
};
