import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Box, HStack, VStack, View, Pressable, Spacer, ScrollView } from 'native-base';
import { find, isEmpty, map, range, startCase, toLower } from 'lodash';
import moment from 'moment';

import { Button, Text, SearchField, ButtonGroup } from '@pimm/base';
import { CleaningScheduleTypeEnum, Employee, ShiftDto, ShiftEmployeeDto } from '@pimm/services/lib/sms-workforce';
import { AddUpdateCleaningTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { formatTo, formatToTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { formatName } from '@app/utils/string-formatter';
import { useEmployeesByRoles } from '@app/features/store-info/hooks';
import { useSiteTime } from '@app/features/store-core';
import { ResourceLoader } from '@app/components/shared';
import { CleaningTaskAssignee } from '../reducers';
import { useCleaningAssignments } from '../context';

export type SelectEmployeeProps = {
  assignee?: CleaningTaskAssignee;
  scheduleDate?: Date;
  scheduleType?: CleaningScheduleTypeEnum;
  taskId: number;
  onChange?: (employee: ShiftEmployeeDto, date: Date) => void;
  onClose?: () => void;
};

export const SelectEmployee = ({ scheduleDate, scheduleType, taskId, ...props }: SelectEmployeeProps) => {
  const siteTime = useSiteTime();
  const { employees, week } = useCleaningAssignments();
  let scheduleDay = formatTo(scheduleDate, 'ddd');

  if (scheduleType !== CleaningScheduleTypeEnum.Daily) {
    // Get the current date of the week
    const dayBlock = siteTime.toStartEndOfBlock();
    const startEndOfWeek = siteTime.toStartEndOfWeek(scheduleDate);
    if (dayBlock.startTime! >= startEndOfWeek.startDate) {
      scheduleDay = formatTo(dayBlock.startTime, 'ddd');
    }
  }

  const [searchValue, setSearchValue] = useState<string>();
  const [employeeId, setEmployeeId] = useState<string | undefined>(props.assignee?.employeeId);
  const [schedules, setSchedules] = useState<{ day: string; employees: ShiftDto[] }[]>([]);
  const [weekDay, setWeekDay] = useState<string>(scheduleDay ?? 'Mon');

  const findEmployees = useMemo<Employee[]>(() => {
    const employees = find(schedules, _ => _.day === weekDay)?.employees;

    if (employees && !isEmpty(employees)) {
      return employees
        .filter(emp => emp.employee)
        .map(({ employee, ..._ }) => ({
          ..._,
          ...employee,
          employeeId: employee?.id,
          startTime: _.startTime,
          endTime: _.endTime,
        })) as Employee[];
    }

    return [];
  }, [schedules, weekDay]);

  const sections = useEmployeesByRoles(findEmployees, { searchValue });

  const addUpdateCleaningTaskAssignment = useMutation({ mutationFn: AddUpdateCleaningTaskAssignment });

  const handlePressSelect = (employee: ShiftEmployeeDto) => () => {
    setEmployeeId(employee.id);
  };

  const handlePressSave = async () => {
    const shift = find(employees.data?.data, ['employee.id', employeeId]);
    const date = range(7).reduce((_date: Date | undefined, day) => {
      const d = week.startDate.addDays(day);

      if (weekDay === formatTo(d, 'ddd')) return d;

      return _date;
    }, week.startDate);

    if (shift?.employee && props.onChange) {
      props.onChange(shift.employee, date!);
    }
  };

  useEffect(() => {
    const results = range(7).map(day => {
      const weekDate = moment(week.startDate).add(day, 'day').toDate();
      const dayBlock = siteTime.toStartEndOfBlock(weekDate);
      let result = employees.data?.data?.filter(_ => {
        const startTime = stringToDateLocal(_.startTime);
        return !!startTime && startTime >= dayBlock.startTime! && startTime < dayBlock.endTime!;
      });

      return {
        day: formatTo(weekDate, 'ddd')!,
        employees: result ?? [],
      };
    });
    setSchedules(results);
  }, [employees.data?.data, week.startDate]);

  return (
    <View w="full" h="full">
      <HStack alignItems="center" px={4} h="56px" borderBottomWidth={1}>
        <Text size="2xl" fontWeight={700} color="gray.900">
          Select Employee
        </Text>
      </HStack>

      <HStack flex={1} p={4} space={5}>
        {/* Employee List */}
        <VStack flex={1} space={4} position="relative" h="full">
          <Box flex={1}>
            <VStack rounded="xl" overflow="hidden" h="full" w="full" borderColor="gray.200" borderWidth={1}>
              <HStack space={3} height={68} px={4} py={6} alignItems="center" justifyContent="space-between" borderBottomWidth={1}>
                <Box flex={1} maxW="320px">
                  <SearchField
                    placeholder="Search Employee"
                    value={searchValue}
                    onChangeText={setSearchValue}
                    onClear={() => setSearchValue(undefined)}
                  />
                </Box>
                {scheduleType !== 'Daily' && (
                  <ButtonGroup value={weekDay} onChange={setWeekDay}>
                    {schedules.map(_ => {
                      return (
                        <ButtonGroup.Item
                          key={_.day}
                          value={_.day}
                          w={{ base: '70px', lg: '80px' }}
                          badgeIcon={{ _container: { w: '24px' }, show: true, value: _.employees.length ?? 0 }}
                        >
                          {_.day}
                        </ButtonGroup.Item>
                      );
                    })}
                  </ButtonGroup>
                )}
              </HStack>

              {/* List of employees */}
              <Box flex={1} bg="gray.50">
                <ResourceLoader h="full" w="full" emptyMessage="No available employees" isEmpty={isEmpty(sections)}>
                  <ScrollView py={3} h="full">
                    <VStack space={2}>
                      {map(sections, section => {
                        return (
                          <Box key={section.title} px={3}>
                            <Box mb={1} px={1}>
                              <Text color="gray.900" fontWeight={700} size="lg" lineHeight="sm">
                                {section.title}
                              </Text>
                            </Box>

                            <HStack flexWrap="wrap">
                              {map(section.data, _section => {
                                const isActive = !!employeeId && _section?.id === employeeId;
                                const fullName = formatName(_section?.firstName, _section?.lastName);
                                const startTime = stringToDateLocal(_section.startTime);
                                const endTime = stringToDateLocal(_section.endTime);
                                return (
                                  <Box key={`${_section.id}`} flex="none" p={1} w="1/6">
                                    <Pressable rounded="lg" onPress={handlePressSelect(_section!)}>
                                      {({ isHovered }) => (
                                        <Box
                                          position="relative"
                                          rounded="lg"
                                          alignItems="center"
                                          justifyContent="center"
                                          px={2}
                                          h="64px"
                                          borderWidth={1}
                                          borderColor={isActive ? 'primary.200' : isHovered ? 'gray.200' : 'white'}
                                          bg={isActive ? 'primary.50' : 'white'}
                                        >
                                          <Text
                                            color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.700'}
                                            fontWeight={600}
                                            size="md"
                                            lineHeight="sm"
                                            textAlign="center"
                                            numberOfLines={2}
                                            ellipsizeMode="tail"
                                          >
                                            {startCase(toLower(fullName))}
                                          </Text>
                                          {!!startTime && !!endTime && (
                                            <Text
                                              color={isActive ? 'primary.700' : isHovered ? 'black' : 'gray.600'}
                                              fontWeight={500}
                                              size="md"
                                              lineHeight="sm"
                                              textAlign="center"
                                            >
                                              {`${formatToTimeOnly(startTime)} - ${formatToTimeOnly(endTime)}`}
                                            </Text>
                                          )}
                                        </Box>
                                      )}
                                    </Pressable>
                                  </Box>
                                );
                              })}
                            </HStack>
                          </Box>
                        );
                      })}
                    </VStack>
                  </ScrollView>
                </ResourceLoader>
              </Box>
            </VStack>
          </Box>
        </VStack>
      </HStack>

      <HStack space={2} alignItems="center" py={2.5} px={4} borderTopWidth={1}>
        <Spacer />
        <Button variant="unstyled" minWidth={100} disabled={addUpdateCleaningTaskAssignment.isLoading} onPress={props.onClose}>
          Cancel
        </Button>
        <Button minWidth={100} isLoading={addUpdateCleaningTaskAssignment.isLoading} isDisabled={!employeeId} onPress={handlePressSave}>
          Save
        </Button>
      </HStack>
    </View>
  );
};
