import { useEffect, useMemo, useState } from 'react';

import { EmployeeDto } from '@pimm/services/lib/sms-workforce';
import { isEmpty } from 'lodash';

export type EmployeeRole = 'manager' | 'user' | 'employee';

type Employee = Partial<EmployeeDto> & {
  startTime?: string;
  endTime?: string;
};

type EmployeesByRolesReturn = { title?: string; data: Employee[] }[];

export const useEmployeesByRoles = (
  employees?: Employee[],
  query?: { roles?: EmployeeRole[]; searchValue?: string },
): EmployeesByRolesReturn => {
  const [sections, setSections] = useState<EmployeesByRolesReturn>([]);

  const searchResults = useMemo(() => {
    const keyword = query?.searchValue?.trim();

    if (!keyword || isEmpty(keyword)) return sections;

    return sections
      .map(_section => {
        const result = _section.data.filter(_ =>
          [_.firstName, _.lastName].filter(Boolean).join(' ').toLowerCase().includes(keyword.toLowerCase()),
        );
        return { ..._section, data: result };
      })
      .filter(_ => _.data.length);
  }, [sections, query?.searchValue]);

  useEffect(() => {
    const sections: EmployeesByRolesReturn = [];
    const roles = [
      { title: 'Managers', roles: ['manager', 'user'] },
      { title: 'Employees', roles: ['employee'] },
    ].filter(_ => {
      return isEmpty(query?.roles) || query?.roles?.some(role => _.roles.includes(role));
    });

    roles.forEach(_ => {
      const data = employees?.filter(({ role }) => !!role && _.roles.some(_role => `sms ${_role}` === role?.toLowerCase())) ?? [];

      if (data?.length) {
        sections.push({ title: _.title, data: data });
      }
    });

    setSections(sections);
  }, [employees, query?.roles]);

  return searchResults;
};
