import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';

import { AutoAssignOpsTaskAssignment } from '@pimm/services/lib/sms-workforce/services';
import { GetOpsTasksPositioningParams } from './get-ops-tasks-positioning.hook';

interface MutateAutoAssignOpsPositioningVariables {
  date?: Date;
  siteId?: string;
}

export const useMutateAutoAssignOpsPositioning = (
  props?: UseMutationOptions<Awaited<ReturnType<typeof AutoAssignOpsTaskAssignment>>, unknown, MutateAutoAssignOpsPositioningVariables>,
): UseMutationResult<unknown, unknown, MutateAutoAssignOpsPositioningVariables> => {
  const queryClient = useQueryClient();

  const autoAssignOpsTaskAssignment = useMutation({
    mutationFn: ({ date, siteId }: MutateAutoAssignOpsPositioningVariables) =>
      AutoAssignOpsTaskAssignment({
        date: moment(date).format().slice(0, 19),
        siteId: siteId,
      }),
    onSuccess: (data, { date, siteId }) => {
      const queryCache = queryClient.getQueryCache();

      queryCache.getAll().forEach(cache => {
        if (cache.queryKey[0] === 'GetOpsTasksFlowChartAggLive') {
          queryClient.refetchQueries({ queryKey: ['GetOpsTasksFlowChartAggLive'] });
        } else if (cache.queryKey[0] === 'GetOpsTasksPositioning') {
          const params: GetOpsTasksPositioningParams = { date: date!, siteId: siteId };
          queryClient.refetchQueries({ queryKey: ['GetOpsTasksPositioning', params] });
        }
      });
    },
  });

  return autoAssignOpsTaskAssignment;
};
