import { useCallback } from 'react';
import { find, first, forEach, last, omit, sortBy } from 'lodash';
import moment from 'moment';

import { Daypart as DopDaypart, DopBlock } from '@pimm/services/lib/sms-tenants';
import { useSiteConfig } from '../context/site-config.context';

interface TimeDuration {
  startTime?: Date;
  endTime?: Date;
}

export type DayBlock = Omit<DopBlock, 'startTime' | 'endTime'> & {
  durationInMinutes?: number;
  startTime: Date;
  staffStartTime?: Date;
  endTime: Date;
};

export type Daypart = Omit<DopDaypart, 'startTime' | 'endTime'> & {
  durationInMinutes?: number;
  startTime: Date;
  endTime: Date;
};

export type DailyOperationHours = {
  dayOfWeek: number;
  isClosed: boolean;
  iServesBreakfast: boolean;
  hasPickupWindow: boolean;
  breakfastStart?: Date;
  breakfastEnd?: Date;
  diningRoomOpen?: Date;
  diningRoomClose?: Date;
  dinnerStart?: Date;
  dinnerEnd?: Date;
  driveThruOpen?: Date;
  driveThruClose?: Date;
  lunchStart?: Date;
  lunchEnd?: Date;
  staffStart?: Date;
  staffEnd?: Date;
  storeOpen?: Date;
  storeClose?: Date;
};

type SiteLocalTimeReturn = {
  siteId?: string;
  firstDayOfWeek?: number;
  utcOffsetInMinutes?: number;
  today: () => Date;
  toDayBlocks: (date?: Date) => DayBlock[];
  toDayBlockNow: (date?: Date) => DayBlock;
  toStartEndOfBlock: (date?: Date) => {
    startTime?: Date;
    staffStartTime?: Date;
    endTime?: Date;
  };
  toStartEndOfWeek: (date?: Date) => {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  };
  toStartEndOfWeekBlock: (date?: Date) => {
    weekNumber: number;
    startTime: Date;
    endTime: Date;
  };
  toOperationHours: (date: Date) => DailyOperationHours | undefined;
  toDayparts: (date?: Date) => Daypart[];
  toDaypartNow: (date?: Date) => Daypart;
  toStartEndOfPart: (date?: Date) => {
    startTime: Date;
    endTime: Date;
  };
  toStartEndOfWeekPart: (date?: Date) => {
    weekNumber: number;
    startTime: Date;
    endTime: Date;
  };

  isTimeBetweenDayBlocks: (date?: Date, time?: Date) => boolean;
  isTimeBetweenWeekBlocks: (date?: Date, time?: Date) => boolean;
};

export const useSiteTime = (): SiteLocalTimeReturn => {
  const {
    siteConfig: { id: siteId, config, utcOffsetInMinutes },
  } = useSiteConfig();

  const startOfWeek = useCallback(
    (date?: Date): ReturnType<typeof moment> => {
      const startOfWeekDay = config?.storeHoursConfig?.firstDayOfWeek ?? 1;
      const dt = date ?? new Date().targetOffset(utcOffsetInMinutes);
      const dayOfWeek = dt.getDay(); // Get the current day of the week (0 - Sunday, 6 - Saturday)
      const distanceToStartOfWeek = (dayOfWeek - startOfWeekDay + 7) % 7; // Calculate difference
      const startOfWeekDate = moment(date); // Create a copy of the date
      startOfWeekDate.set('date', dt.getDate() - distanceToStartOfWeek); // Subtract difference to get start of week
      return startOfWeekDate;
    },
    [config?.storeHoursConfig?.firstDayOfWeek, utcOffsetInMinutes],
  );

  // return current local time
  const today = useCallback((): Date => {
    return new Date().targetOffset(utcOffsetInMinutes);
  }, [utcOffsetInMinutes]);

  const toOperationHours = useCallback(
    (date?: Date): DailyOperationHours | undefined => {
      const dt = date ?? new Date().targetOffset(utcOffsetInMinutes);
      const day = find(config?.storeHoursConfig?.dailyOpHours, ['dayOfWeek', dt.getDay()]);

      if (!day) return undefined;
      return {
        dayOfWeek: day.dayOfWeek as number,
        isClosed: day.isClosed ?? false,
        iServesBreakfast: day.servesBreakfast ?? false,
        hasPickupWindow: day.hasPickupWindow ?? false,
        breakfastStart: dt.toTimeValue(day.breakfastStart),
        breakfastEnd: dt.toTimeValue(day.breakfastEnd),
        diningRoomOpen: dt.toTimeValue(day.diningRmOpen),
        diningRoomClose: dt.toTimeValue(day.diningRmClose),
        dinnerStart: dt.toTimeValue(day.dinnerStart),
        dinnerEnd: dt.toTimeValue(day.dinnerEnd),
        driveThruOpen: dt.toTimeValue(day.driveThruOpen),
        driveThruClose: dt.toTimeValue(day.driveThruClose),
        lunchStart: dt.toTimeValue(day.lunchStart),
        lunchEnd: dt.toTimeValue(day.lunchEnd),
        staffStart: dt.toTimeValue(day.staffStart),
        staffEnd: dt.toTimeValue(day.staffEnd),
        storeOpen: dt.toTimeValue(day.storeOpen),
        storeClose: dt.toTimeValue(day.storeClose),
      };
    },
    [config?.storeHoursConfig?.dailyOpHours, utcOffsetInMinutes],
  );

  const toDayBlocks = useCallback(
    (date?: Date): DayBlock[] => {
      const today = new Date().targetOffset(utcOffsetInMinutes);
      const dopBlocks = sortBy(config?.storeHoursConfig?.dopBlocks, 'blockNumber');
      const dayBlocks: DayBlock[] = [];

      if (dopBlocks?.length) {
        for (let index = 0; index < dopBlocks.length; index++) {
          const dopBlock: DopBlock = dopBlocks[index];
          const dayBlock: Partial<DayBlock> = {
            ...omit(dopBlock, ['startTime', 'endTime']),
          };

          forEach(['startTime', 'endTime'], (dataField: keyof Pick<DopBlock, 'startTime' | 'endTime'>) => {
            const value = dopBlock[dataField];
            const dt = new Date(date?.getTime() ?? today?.getTime());
            // Split the input string by the dot (.)
            const parts = value?.toString().split('.') ?? [];
            const timeComponents = last(parts)?.split(':');
            let addDays = 0;

            // Extract days and time components
            if (parts.length > 1) addDays = parseInt(parts[0], 10);

            if (timeComponents?.length) {
              dt.setHours(parseInt(timeComponents[0], 10));
              dt.setMinutes(parseInt(timeComponents[1], 10));
              dt.setSeconds(0, 0);

              if (addDays) dt.setDate(dt.getDate() + addDays);
              if (dt) dayBlock[dataField] = dt;
            }
          });

          if (dayBlock.startTime && dayBlock.endTime) {
            dayBlock.durationInMinutes = dayBlock.endTime.timeDiffInMinutes(dayBlock.startTime);
            dayBlocks.push(dayBlock as DayBlock);
          }
        }
      }

      // Check if, the day change and the current time is still not part of the day blocks
      if (!date && dayBlocks.length && today < dayBlocks[0].startTime) {
        dayBlocks.forEach(db => {
          db.startTime.setDate(db.startTime.getDate() - 1);
          db.endTime.setDate(db.endTime.getDate() - 1);
        });
      }

      const opHours = toOperationHours(dayBlocks[0]?.startTime);

      // Return the start of staffTime from Operation hours
      if (opHours?.staffStart) {
        dayBlocks[0].staffStartTime = new Date(Math.max(opHours.staffStart.getTime(), dayBlocks[0].startTime.getTime()));
      }

      return dayBlocks;
    },
    [config?.storeHoursConfig?.dopBlocks, utcOffsetInMinutes],
  );

  const toDayBlockNow = useCallback(
    (date?: Date): DayBlock => {
      const today = new Date().targetOffset(utcOffsetInMinutes);
      const dayBlocks = toDayBlocks(date);
      const dayBlock = find(dayBlocks, ({ startTime, endTime }) => today >= startTime && today < endTime);
      return dayBlock ?? dayBlocks[0];
    },
    [config?.storeHoursConfig?.dopBlocks, utcOffsetInMinutes],
  );

  const toStartEndOfBlock = useCallback(
    (date?: Date) => {
      const dayBlocks = toDayBlocks(date);
      const startOfBlock = first(dayBlocks);
      return {
        startTime: startOfBlock?.startTime,
        staffStartTime: startOfBlock?.staffStartTime,
        endTime: last(dayBlocks)?.endTime,
      };
    },
    [config?.storeHoursConfig?.dopBlocks, utcOffsetInMinutes],
  );

  const toStartEndOfWeek = useCallback(
    (date?: Date): ReturnType<SiteLocalTimeReturn['toStartEndOfWeek']> => {
      const dt = date ?? new Date().targetOffset(utcOffsetInMinutes);
      const startOfDate = startOfWeek(dt);
      return {
        weekNumber: startOfDate.week(),
        startDate: startOfDate.clone().toDate(),
        endDate: startOfDate.clone().add(6, 'days').toDate(),
      };
    },
    [config?.storeHoursConfig?.firstDayOfWeek, utcOffsetInMinutes],
  );

  const toStartEndOfWeekBlock = useCallback(
    (date?: Date): ReturnType<SiteLocalTimeReturn['toStartEndOfWeekBlock']> => {
      const dayblocks = toStartEndOfBlock(date);
      const week = toStartEndOfWeek(dayblocks.startTime);
      const startTime = toStartEndOfBlock(week.startDate).startTime!;
      const endTime = toStartEndOfBlock(week.endDate).endTime!;

      return {
        weekNumber: week.weekNumber,
        startTime: startTime,
        endTime: endTime,
      };
    },
    [config?.storeHoursConfig?.firstDayOfWeek, utcOffsetInMinutes],
  );

  const toDayparts = useCallback(
    (date?: Date): Daypart[] => {
      const today = new Date().targetOffset(utcOffsetInMinutes);
      const dopDayparts = sortBy(config?.storeHoursConfig?.dayparts, 'partNumber');
      const dayparts: Daypart[] = [];

      if (dopDayparts?.length) {
        for (let index = 0; index < dopDayparts.length; index++) {
          const dopDaypart: DopDaypart = dopDayparts[index];
          const daypart: Partial<Daypart> = {
            ...omit(dopDaypart, ['startTime', 'endTime']),
          };

          forEach(['startTime', 'endTime'], (dataField: keyof Pick<DopBlock, 'startTime' | 'endTime'>) => {
            const value = dopDaypart[dataField];
            const dt = new Date(date?.getTime() ?? today?.getTime());
            // Split the input string by the dot (.)
            const parts = value?.toString().split('.') ?? [];
            const timeComponents = last(parts)?.split(':');
            let addDays = 0;

            // Extract days and time components
            if (parts.length > 1) addDays = parseInt(parts[0], 10);

            if (timeComponents?.length) {
              dt.setHours(parseInt(timeComponents[0], 10));
              dt.setMinutes(parseInt(timeComponents[1], 10));
              dt.setSeconds(0, 0);

              if (addDays) dt.setDate(dt.getDate() + addDays);
              if (dt) daypart[dataField] = dt;
            }
          });

          if (daypart?.startTime && daypart?.endTime) {
            // Prevent endTime that is before the startTime
            if (daypart.endTime < daypart.startTime) {
              daypart.endTime = moment(daypart.endTime).add(1, 'day').toDate();
            }

            daypart.durationInMinutes = daypart.endTime.timeDiffInMinutes(daypart.startTime);
            dayparts.push(daypart as Daypart);
          }
        }
      }

      // Check if, the day change and the current time is still not part of the day part
      if (!date && dayparts.length && today < dayparts[0].startTime) {
        dayparts.forEach(db => {
          db.startTime.setDate(db.startTime.getDate() - 1);
          db.endTime.setDate(db.endTime.getDate() - 1);
        });
      }
      return dayparts;
    },
    [config?.storeHoursConfig?.dayparts, utcOffsetInMinutes],
  );

  const toDaypartNow = useCallback(
    (date?: Date): DayBlock => {
      const today = new Date().targetOffset(utcOffsetInMinutes);
      const dayparts = toDayparts(date);
      const daypart = find(dayparts, ({ startTime, endTime }) => today >= startTime && today < endTime);
      return daypart ?? dayparts[0];
    },
    [config?.storeHoursConfig?.dayparts, utcOffsetInMinutes],
  );

  const toStartEndOfPart = useCallback(
    (date?: Date): ReturnType<SiteLocalTimeReturn['toStartEndOfPart']> => {
      const dayparts = toDayparts(date);
      const startOfBlock = first(dayparts);
      return {
        startTime: startOfBlock?.startTime!,
        endTime: last(dayparts)?.endTime!,
      };
    },
    [config?.storeHoursConfig?.dayparts, utcOffsetInMinutes],
  );

  const toStartEndOfWeekPart = useCallback(
    (date?: Date): ReturnType<SiteLocalTimeReturn['toStartEndOfWeekPart']> => {
      const dayparts = toStartEndOfPart(date);
      const week = toStartEndOfWeek(dayparts.startTime);
      const startTime = toStartEndOfPart(week.startDate).startTime!;
      const endTime = toStartEndOfPart(week.endDate).endTime!;

      return {
        weekNumber: week.weekNumber,
        startTime: startTime,
        endTime: endTime,
      };
    },
    [config?.storeHoursConfig?.firstDayOfWeek, config?.storeHoursConfig?.dayparts, utcOffsetInMinutes],
  );

  const isTimeBetweenDayBlocks = useCallback(
    (date?: Date, time?: Date) => {
      const dt = time ?? today();
      const dayblock = toStartEndOfBlock(date);

      if (!dayblock.startTime || !dayblock.endTime) return false;
      return dt >= dayblock.startTime && dt < dayblock.endTime;
    },
    [config?.storeHoursConfig?.dopBlocks, utcOffsetInMinutes],
  );

  const isTimeBetweenWeekBlocks = useCallback(
    (date?: Date, time?: Date) => {
      const dt = time ?? today();
      const weekBlock = toStartEndOfWeekBlock(date);

      if (!weekBlock.startTime || !weekBlock.endTime) return false;
      return dt >= weekBlock.startTime && dt < weekBlock.endTime;
    },
    [config?.storeHoursConfig?.dopBlocks, utcOffsetInMinutes],
  );

  return {
    // return the site id
    siteId: siteId,
    // return the starting day of week 0 = Sunday, 1 = Monday
    firstDayOfWeek: config?.storeHoursConfig?.firstDayOfWeek,
    // return the site utc offset in minutes
    utcOffsetInMinutes: utcOffsetInMinutes,
    // return the site local time
    today: today,
    // return all dayBlocks for the given date
    toDayBlocks: toDayBlocks,
    // return the current dayBlock or the first dayBlock of the given date
    toDayBlockNow: toDayBlockNow,
    // return the start and end of week
    toStartEndOfWeek: toStartEndOfWeek,
    // return the start and end of week and dayblock
    toStartEndOfWeekBlock: toStartEndOfWeekBlock,
    // return the start and endTime of the given day blocks
    toStartEndOfBlock: toStartEndOfBlock,
    // return the config daily operation hours
    toOperationHours: toOperationHours,

    // return the config daypart hours
    toDayparts: toDayparts,
    // return the current daypart or the first daypart of the given date
    toDaypartNow: toDaypartNow,
    // return the start and endTime of the given daypart
    toStartEndOfPart: toStartEndOfPart,
    // return the start and end of week and daypart
    toStartEndOfWeekPart: toStartEndOfWeekPart,

    // Check if the given time is in between date/dayblock
    isTimeBetweenDayBlocks,
    // Check if the given time is in between entire week/start and end of block
    isTimeBetweenWeekBlocks,
  };
};
