import { useMemo, useState } from 'react';
import { Box, Button, HStack, IconButton, Spacer, VStack, useTheme } from 'native-base';
import { find, isEmpty, sortBy } from 'lodash';
import Feather from '@expo/vector-icons/Feather';
import { FlashList } from '@shopify/flash-list';
import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons';

import { ButtonGroup, CloseIcon, SearchField, Text } from '@pimm/base';
import { SiteSimpleDto } from '@pimm/services/lib/sms-tenants';
import { useAppSelector } from '@app/store/store';
import { selectUserProfile } from '@app/store/selectors';
import { completeAddress } from '@app/utils/complete-address';
import { ResourceLoader } from '@app/components/shared';
import PressableSite from './pressable-site';
import { useBoolean, useTimeout } from 'usehooks-ts';

type SiteListProps = {
  siteId?: string;
  onClose: () => void;
  onSelectSite: (site: SiteSimpleDto) => void;
};

export const SiteList = ({ siteId, onSelectSite, ...props }: SiteListProps) => {
  const { colors } = useTheme();
  const userProfile = useAppSelector(selectUserProfile);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(siteId);
  const [tabFocus, setTabFocus] = useState<'Active' | 'No Service'>('Active');
  const isLoading = useBoolean(true);

  const [plans] = useState([
    { icon: <Feather col name="tablet" />, planTypes: ['Basic', 'BasicPrep', 'Plus', 'Premium'] },
    { icon: <Feather name="grid" />, planTypes: ['Plus', 'Premium', 'Dop'] },
    { icon: <SimpleLineIcons name="printer" />, planTypes: ['BasicPrep', 'Premium', 'PrepLabel'] },
  ]);

  const siteList = useMemo(() => {
    const { sites = [] } = userProfile ?? {};
    const filteredSites = sites.filter(site => (tabFocus === 'Active' ? site.isActive : !site.isActive));

    if (!isEmpty(searchValue?.trim())) {
      const loweredSearchValue = searchValue.toLowerCase();
      return filteredSites.filter(
        site =>
          site.name?.toLowerCase().includes(loweredSearchValue) ||
          completeAddress(site.address)?.toLowerCase().includes(loweredSearchValue),
      );
    }

    return sortBy(filteredSites, ['name']);
  }, [userProfile?.sites, searchValue, tabFocus]);

  const handleApplySite = () => {
    const site = find(userProfile.sites, ['id', selectedSiteId]);
    if (site) {
      onSelectSite(site);
      props.onClose();
    }
  };

  useTimeout(() => isLoading.setFalse(), 300);

  return (
    <VStack w="full" h="full">
      {/* Header */}
      <HStack justifyContent="space-between" alignItems="center" h="56px" pl={4} pr={3}>
        <Text size="2xl" fontWeight={600} color="gray.900">
          Select a Site
        </Text>
        <IconButton
          variant="unstyled"
          rounded="sm"
          p={1}
          icon={<CloseIcon size="18px" color={colors.gray[700]} />}
          _pressed={{ bg: 'gray.100' }}
          onPress={props.onClose}
        />
      </HStack>

      {/* Body */}
      <VStack flex={1}>
        <HStack alignItems="center" justifyContent="space-between" py={2} px={4} borderWidth="1px" borderColor="gray.200">
          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
            <ButtonGroup.Item value="Active" minW={90}>
              Active
            </ButtonGroup.Item>
            <ButtonGroup.Item value="No Service" minW={90}>
              No Service
            </ButtonGroup.Item>
          </ButtonGroup>

          <Spacer />

          <Box w={320}>
            <SearchField placeholder="Search Sites" value={searchValue} onChangeText={setSearchValue} onClear={() => setSearchValue('')} />
          </Box>
        </HStack>

        <Box flex={1}>
          <ResourceLoader h="full" w="full" emptyMessage="No Data Available" isEmpty={siteList.length === 0} isLoading={isLoading.value}>
            {!isLoading.value && (
              <FlashList
                contentContainerStyle={{ paddingHorizontal: 15 }}
                data={siteList}
                drawDistance={300}
                keyExtractor={(item, index) => `${item?.id || index}`}
                estimatedItemSize={1000}
                extraData={selectedSiteId}
                numColumns={4}
                renderItem={({ item, index }) => {
                  return (
                    <Box key={`${item?.id}.${index}`} flex={1} p={1} minHeight={130}>
                      <PressableSite site={item} selectedSiteId={selectedSiteId} plans={plans} onPress={id => setSelectedSiteId(id)} />
                    </Box>
                  );
                }}
              />
            )}
          </ResourceLoader>
        </Box>
      </VStack>

      {/* Footer */}
      <VStack py={3} px={4} borderTopWidth="1px" borderTopColor="gray.200">
        <Button
          alignSelf="flex-end"
          minW={120}
          isDisabled={!selectedSiteId || selectedSiteId === siteId || tabFocus === 'No Service'}
          onPress={handleApplySite}
        >
          Apply
        </Button>
      </VStack>
    </VStack>
  );
};
