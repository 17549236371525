import React, { memo } from 'react';
import { Box, Pressable, HStack, VStack, useTheme } from 'native-base';
import SimpleLineIcons from '@expo/vector-icons/SimpleLineIcons';
import { isEqual } from 'lodash';

import { SiteSimpleDto } from '@pimm/services/lib/sms-tenants';
import { Text } from '@pimm/base';
import { completeAddress } from '@app/utils/complete-address';

type PlanType = {
  icon: React.ReactNode;
  planTypes: string[];
};

type PressableSiteProps = {
  site: SiteSimpleDto;
  selectedSiteId?: string;
  plans: PlanType[];
  onPress: (siteId?: string) => void;
};

const PressableSite = ({ site, selectedSiteId, plans, onPress }: PressableSiteProps) => {
  const { colors } = useTheme();
  const isSelected = site?.id === selectedSiteId;

  const handlePress = () => {
    if (site.isActive) onPress(site?.id);
  };

  return (
    <Pressable
      w="full"
      h="full"
      rounded="lg"
      borderWidth={1}
      isDisabled={!site.isActive}
      borderColor={isSelected ? 'primary.500' : 'gray.300'}
      bgColor={isSelected ? 'primary.50' : 'white'}
      shadow={0}
      onPress={handlePress}
    >
      <VStack space={2} p={3} h="full" opacity={site?.isActive ? 1 : 0.3}>
        <Text size="md" fontWeight={700} color="black" lineHeight="sm">
          {site.name}
        </Text>

        <Box flex={1}>
          <HStack space={1}>
            <SimpleLineIcons name="location-pin" size={12} color="black" />
            <Text size="sm" fontWeight={500} color="gray.800" lineHeight="sm" noOfLines={2} ellipsizeMode="tail">
              {completeAddress(site.address)}
            </Text>
          </HStack>
        </Box>

        <HStack
          py={1}
          px={2}
          rounded="md"
          bgColor="gray.25"
          borderColor={isSelected ? 'primary.100' : 'gray.100'}
          borderWidth={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text size="sm" fontWeight={700} color="black" lineHeight="sm" textTransform="uppercase">
            {site?.smsPlan ?? 'None'}
          </Text>
          <HStack space={1}>
            {plans.map((plan, index) => {
              const hasPlan = !!site.smsPlan && plan.planTypes.includes(site.smsPlan);
              return (
                <Box
                  key={`${site.id}.${index}`}
                  rounded="md"
                  borderWidth={1}
                  borderColor={hasPlan ? 'success.400' : 'gray.400'}
                  size="6"
                  alignItems="center"
                  justifyContent="center"
                >
                  {React.cloneElement(plan.icon as React.ReactElement, {
                    color: hasPlan ? colors.success[700] : colors.gray[500],
                    size: 15,
                  })}
                </Box>
              );
            })}
          </HStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};

const areEqual = (prevProps: PressableSiteProps, nextProps: PressableSiteProps) => {
  /* if the props are equal, it won't update */
  return (
    prevProps.site.id === nextProps.site.id &&
    prevProps.selectedSiteId === nextProps.selectedSiteId &&
    isEqual(prevProps.site.smsPlan, nextProps.site.smsPlan)
  );
};

export default memo(PressableSite, areEqual);
