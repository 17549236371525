import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { map, orderBy, sortBy, startCase, toLower } from 'lodash';

import { stringToDateLocal } from '@app/utils/date-formatter';
// import { useGetBrandSalesVolumeProfiles, useUpdateOpsTasksPositioning } from '../hooks';
// import { useGetOpsTasksPositioning } from '../hooks/get-ops-tasks-positioning.hook';
import {
  OpsPhaseAssignment,
  OpsPhasePositionAssignment,
  OpsPhasePositioning,
  OpsPhasePositioningAction,
  OpsPhasePositioningInitialState,
  OpsPhasePositioningReducer,
} from '../reducers/ops-phase-positioning.reducer';
import { useGetBrandSalesVolumeProfiles, useGetOpsTasksPositioning, useUpdateOpsTasksPositioning } from '../hooks';

interface OpsPhasePositioningContextReturn {
  positioning: OpsPhasePositioning;
  opsTasksPositioning: ReturnType<typeof useGetOpsTasksPositioning>;
  salesVolumeProfiles: ReturnType<typeof useGetBrandSalesVolumeProfiles>[0];
  siteId: string;
  updatePosition: (opsPhaseId: number, position: Partial<OpsPhasePositionAssignment>) => void;
}

type OpsPhasePositioningProviderProps = Pick<OpsPhasePositioningContextReturn, 'opsTasksPositioning' | 'salesVolumeProfiles'> & {
  children: React.ReactNode;
  selectedDate?: Date;
  siteId: string;
};

const OpsPhasePositioningContext = createContext<OpsPhasePositioningContextReturn>(undefined!);

export const OpsPhasePositioningProvider = ({
  children,
  opsTasksPositioning,
  salesVolumeProfiles,
  selectedDate,
  siteId,
}: OpsPhasePositioningProviderProps) => {
  const [positioning, dispatch] = useReducer(OpsPhasePositioningReducer, OpsPhasePositioningInitialState);

  const updateOpsTasksPositioning = useUpdateOpsTasksPositioning();

  const updatePosition: OpsPhasePositioningContextReturn['updatePosition'] = useCallback(
    (opsPhaseId, position) => {
      if (siteId && selectedDate) {
        // For reference only
        // dispatch({
        //   type: OpsPhasePositioningAction.UPDATE,
        //   payload: { opsPhaseId, position },
        // });
        updateOpsTasksPositioning(
          { siteId: siteId, date: selectedDate },
          {
            id: position.assignee?.id,
            employeeId: position.assignee?.employeeId,
            opsPhaseId: opsPhaseId,
            positionId: position.id,
          },
        );
      }
    },
    [positioning, selectedDate, siteId],
  );

  useEffect(() => {
    let payload: OpsPhasePositioning = OpsPhasePositioningInitialState;

    if (selectedDate) {
      const opsPhases = map(orderBy(opsTasksPositioning.data?.opsPhases, ['rangeStartTime', 'rangeEndTime'], ['asc', 'asc']), _ => {
        const opsPhaseAssignment: OpsPhaseAssignment = {
          id: _.opsPhaseId!,
          phase: _.phase!,
          eligableEmployees: _.eligibleEmployees,
          startTime: stringToDateLocal(_.rangeStartTime),
          endTime: stringToDateLocal(_.rangeEndTime),
          salesVolumeProfileId: _.selectedSalesVolumeProfileId,
          positions: map(sortBy(_.positions, 'title'), position => {
            const positionAssignment: OpsPhasePositionAssignment = {
              id: position.positionId!,
              assignee: position.employeeId
                ? {
                    id: position.opsTaskAssignmentId!,
                    employeeId: position.employeeId!,
                    name: startCase(toLower([position.firstName, position.lastName].filter(Boolean).join(' '))),
                  }
                : undefined,
              color: position.color,
              iconUrl: position.iconUrl,
              startTime: stringToDateLocal(position.tasksStartTime),
              endTime: stringToDateLocal(position.tasksEndTime),
              title: position.title ?? '',
              translations: position.translations,
            };

            return positionAssignment;
          }),
          translations: _.translations,
        };
        return opsPhaseAssignment;
      });

      payload = {
        opsPhases: opsPhases,
      };
    }

    dispatch({ type: OpsPhasePositioningAction.RESET, payload: payload });
  }, [opsTasksPositioning.data, selectedDate]);

  return (
    <OpsPhasePositioningContext.Provider value={{ positioning, opsTasksPositioning, salesVolumeProfiles, siteId, updatePosition }}>
      {children}
    </OpsPhasePositioningContext.Provider>
  );
};

export const OpsPhasePositioningConsumer = OpsPhasePositioningContext.Consumer;

export const useOpsPhasePositioning = () => {
  // get the context
  const context = useContext(OpsPhasePositioningContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useOpsPhasePositioning was used outside of its Provider');
  }
  return context;
};
