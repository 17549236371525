import { TemperatureUnitEnum } from '@pimm/services/lib/sms-tenants';
import { create } from 'zustand';

interface AppGlobalState {
  utcOffset?: number;
  weekday?: number;
  temperatureUnit?: TemperatureUnitEnum;
}

type AppGlobalsGoals = AppGlobalState & {
  setUtcOffset: (_utcOffset?: number) => void;
  setWeekday: (_weekday?: number) => void;
  setTemperatureUnit: (_tempUnit?: TemperatureUnitEnum) => void;
  clear: () => void;
};

export const useAppGlobalsGoals = create<AppGlobalsGoals>(set => ({
  setUtcOffset: _utcOffset => set({ utcOffset: _utcOffset }),
  setWeekday: _weekday => set({ weekday: _weekday }),
  setTemperatureUnit: _tempUnit => set({ temperatureUnit: _tempUnit }),
  clear: () => set({ utcOffset: undefined, weekday: undefined }),
}));
