import { ComponentProps, useState } from 'react';
import { Box, HStack, VStack } from 'native-base';
import moment from 'moment';

import { Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { useSiteConfig } from '../store-core';
import StoreHoursTable from './components/store-hours-table';

export const StoreHours = () => {
  const { locale } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const [sections] = useState<ComponentProps<typeof StoreHoursTable>['sections']>([
    {
      title: 'Store Operating Hours',
      data: [
        { title: 'Closed / Non-Op', dataFields: ['isClosed'] },
        { title: 'Store Hours', dataFields: ['storeOpen', 'storeClose'] },
        { title: 'Dining Room Hours', dataFields: ['diningRoomOpen', 'diningRoomClose'] },
        { title: 'Drive-Thru PUW?', dataFields: ['hasPickupWindow'] },
        { title: 'Drive-Thru Hours', dataFields: ['driveThruOpen', 'driveThruClose'] },
      ],
    },
    {
      title: 'Food Service Hours',
      data: [
        { title: 'Serves Breakfast', dataFields: ['iServesBreakfast'] },
        { title: 'Breakfast Hours', dataFields: ['breakfastStart', 'breakfastEnd'] },
        { title: 'Lunch Hours', dataFields: ['lunchStart', 'lunchEnd'] },
        { title: 'Dinner Hours', dataFields: ['dinnerStart', 'dinnerEnd'] },
      ],
    },
  ]);

  return (
    <VStack space={2} w="full" h="full" py={3}>
      <HStack space={2} px={4} alignContent="flex-start" alignItems="center">
        <Text color="gray.600" size="sm">
          First Day Of The Week
        </Text>
        {typeof siteConfig.config?.storeHoursConfig?.firstDayOfWeek === 'number' && (
          <Text key={locale} color="black" fontWeight={600} size="md">
            {moment.weekdays(siteConfig.config?.storeHoursConfig?.firstDayOfWeek)}
          </Text>
        )}
        <Text color="gray.600" size="sm">
          Open 24 hrs
        </Text>
        {typeof siteConfig.config?.storeHoursConfig?.firstDayOfWeek === 'number' && (
          <Text key={locale} color="black" fontWeight={600} size="md">
            {siteConfig.config?.storeHoursConfig?.open24Hrs === true ? 'Yes' : 'No'}
          </Text>
        )}
      </HStack>
      <Box flex={1} h={{ md: '90%', xl: 'full' }} borderTopWidth={1} borderColor="gray.200">
        <StoreHoursTable sections={sections} />
      </Box>
    </VStack>
  );
};
