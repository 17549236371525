import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { HStack, VStack, View, IconButton } from 'native-base';
import { isEmpty, map } from 'lodash';
import moment from 'moment';

import { ButtonGroup, LightBulbIcon, Modal, Text } from '@pimm/base';
import { useAppLocale } from '@pimm/common';
import { FlowChartAggDto, OpsPhaseProfileDto, OpsTaskAssignmentDto } from '@pimm/services/lib/sms-workforce';
import { formatToShortTimeOnly, stringToDateLocal } from '@app/utils/date-formatter';
import { MainContainer } from '@app/components/core';
import { SignalRContext, SignalRData, SignalREffectKey } from '@app/features/signalr/context';
import { useSiteConfig, useSiteTime } from '@app/features/store-core';
import { ModuleTitle } from '@app/features/app';
import { useGetClientId } from '@app/features/app/hooks';
import { MainScreenProps } from '@app/navigations/root';
import {
  FlowChartChecklist,
  FlowChartChecklistProvider,
  GetOpsTasksFlowChartAggLiveParams,
  OpsPhasePositioningProvider,
  SelectConfirmSalesVolumeProfile,
  SelectSalesVolumeProfiles,
  useGetBrandSalesVolumeProfiles,
  useGetOpsTasksFlowChartAggLive,
  useGetOpsTasksPositioning,
  useUpdateOpsTasksFlowChart,
} from '@app/features/flow-chart';
import { ResourceLoader } from '@app/components/shared';
import { ModalAppDocuments } from '@app/features/store-info/components';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { FlowChartPosition } from '@app/features/flow-chart/reducers/flow-chart-checklist.reducer';
import { SelectEligableEmployee } from '@app/features/flow-chart/components/select-eligable-employee';

export type FlowChartScreenProps = MainScreenProps<'FlowChart'>;

export const FlowChartScreen: React.FC<FlowChartScreenProps> = ({ navigation, route }) => {
  const queryClient = useQueryClient();
  const xClientId = useGetClientId();
  const { translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const siteTime = useSiteTime();
  const modalConfirm = useModalFocus<number>();
  const modalPosition = useModalFocus<FlowChartPosition>();
  const [startOfDay, setStartOfDay] = useState<Date>(siteTime.toStartEndOfBlock().startTime ?? siteTime.today());

  const [opsPhaseProfiles, setOpsPhaseProfiles] = useState<OpsPhaseProfileDto[]>([]);
  const [opsPhaseFocusId, setOpsPhaseFocusId] = useState<number | undefined>(
    route.params?.opsPhaseId ? Number(route.params.opsPhaseId) : undefined,
  );

  const [queryFlowChartAgg, setQueryFlowChartAgg] = useGetOpsTasksFlowChartAggLive();
  const [querySalesVolumeProfiles, setQuerySalesVolumeProfiles] = useGetBrandSalesVolumeProfiles();
  const queryOpsTasksPositioning = useGetOpsTasksPositioning({
    date: startOfDay,
    siteId: siteConfig.id,
  });

  const updateOpsTasksFlowChart = useUpdateOpsTasksFlowChart();

  const activeOpsPhaseId = opsPhaseFocusId || queryFlowChartAgg.data?.flowChartData?.opsPhaseId;
  const startDateTime = stringToDateLocal(queryFlowChartAgg.data?.flowChartData?.startDateTime);
  const isDisabled = !startDateTime || siteTime.today() < new Date(startDateTime.getTime() - 60 * 60 * 1000);

  SignalRContext.useSignalREffect(
    SignalREffectKey,
    jsonString => {
      const queryCache = queryClient.getQueryCache();
      const message: SignalRData<
        OpsTaskAssignmentDto & { opsTaskAssignment?: { assignDate?: string; opsPhaseId?: number; siteId?: string }; opsTaskId?: string }
      > = JSON.parse(jsonString);
      const _data = message.data.data;

      if (!xClientId || message.data.clientId === xClientId) return;

      if (message.moduleName === 'OpsPlan') {
        const opsTaskAssignment = _data.opsTaskAssignment;

        if (message.actionName === 'ProfileChanged') {
          queryClient.invalidateQueries({ queryKey: ['GetOpsTasksqueryFlowChartAggLive'] });
          queryFlowChartAgg.refetch();
          return;
        }

        const cachedGetOpsTasksqueryFlowChartAggLive = queryCache.getAll().find(cache => {
          const params = cache.queryKey[1] as GetOpsTasksFlowChartAggLiveParams;
          const _siteId = _data.siteId || opsTaskAssignment?.siteId;
          const _assignedDate = _data.assignDate ?? opsTaskAssignment?.assignDate;

          if (cache.queryKey.includes('GetOpsTasksqueryFlowChartAggLive') && params.siteId === _siteId) {
            const _queryData = queryClient.getQueryData<FlowChartAggDto>(cache.queryKey);

            const isSameDate = _queryData?.flowChartData?.date?.slice(0, 10) === _assignedDate?.slice(0, 10);
            const isSameOpsPhaseId = _queryData?.flowChartData?.opsPhaseId === (_data.opsPhaseId || opsTaskAssignment?.opsPhaseId);
            return isSameDate && isSameOpsPhaseId;
          }
          return false;
        });

        if (cachedGetOpsTasksqueryFlowChartAggLive?.queryKey[1]) {
          const isDeleteRequest = ['DeleteOpsTaskAssignmentRequestHandler', 'DeleteOpsTaskTrackerRequestHandler'].includes(
            message.data.origin,
          );

          updateOpsTasksFlowChart(cachedGetOpsTasksqueryFlowChartAggLive.queryKey[1], message.actionName, {
            ...message.data.data,
            employeeId: isDeleteRequest ? undefined : message.data.data?.employeeId,
          });
        }
      }
    },
    [],
  );

  const handleConfirmRefetch = () => {
    queryClient.invalidateQueries({ queryKey: ['GetOpsTasksqueryFlowChartAggLive'] });
    queryFlowChartAgg.refetch();
  };

  const handleChangeFocusOpsPhaseId = (opsPhaseId: number) => {
    // Remember the last known selected opsPhaseId
    navigation.setParams({ ...route.params, opsPhaseId: opsPhaseId });
    setOpsPhaseFocusId(opsPhaseId);
  };

  useEffect(() => {
    if (siteConfig) {
      setQuerySalesVolumeProfiles({
        brandId: siteConfig.companyInfo?.brand?.id,
        marketId: siteConfig.config?.marketId,
      });
    }
  }, [siteConfig]);

  useEffect(() => {
    if (siteConfig.id) {
      setQueryFlowChartAgg({
        opsPhaseId: opsPhaseFocusId,
        siteId: siteConfig.id,
      });
    }
  }, [opsPhaseFocusId, siteConfig?.id]);

  useEffect(() => {
    if (queryFlowChartAgg.data?.opsPhaseProfiles?.length) {
      setOpsPhaseProfiles(queryFlowChartAgg.data.opsPhaseProfiles);
    }
  }, [queryFlowChartAgg.data?.opsPhaseProfiles]);

  useEffect(() => {
    let timerRef: ReturnType<typeof setTimeout>;

    if (startOfDay) {
      const nextDay = moment(startOfDay).add(1, 'day').valueOf();
      // Reset start of day
      timerRef = setTimeout(() => {
        setStartOfDay(siteTime.toStartEndOfBlock().startTime ?? siteTime.today());
      }, nextDay - startOfDay.getTime());
    }

    return () => clearTimeout(timerRef);
  }, [startOfDay]);

  return (
    <MainContainer>
      <VStack w="full" h="full">
        <HStack px={4} height={60} alignItems="center" borderWidth={1}>
          <HStack flex={1} alignItems="center">
            <ModuleTitle name={route.name} defaultText="Flow Chart" />
            <ModalAppDocuments
              identifierName="Module.OpsTasks"
              moduleType="Ops Tasks"
              trigger={triggerProps => {
                if (!triggerProps.documents?.length) return undefined;
                return (
                  <IconButton
                    rounded="lg"
                    borderWidth={1}
                    borderColor="gray.300"
                    ml={2}
                    p={0}
                    w={9}
                    h={9}
                    icon={<LightBulbIcon size="md" />}
                    onPress={triggerProps.onPress}
                  />
                );
              }}
            />
          </HStack>

          {!isEmpty(opsPhaseProfiles) && (
            <ButtonGroup value={activeOpsPhaseId} onChange={handleChangeFocusOpsPhaseId}>
              {map(opsPhaseProfiles, opsPhase => {
                const isActive = opsPhase.opsPhaseId === activeOpsPhaseId;
                const startTime = stringToDateLocal(opsPhase.rangeStartTime);
                return (
                  <ButtonGroup.Item key={opsPhase.opsPhaseId} value={opsPhase.opsPhaseId}>
                    <View flexDirection={{ md: 'column', xl: 'row' }} alignItems="center">
                      <Text
                        color={isActive ? 'black' : 'gray.500'}
                        fontWeight={isActive ? 700 : 600}
                        size={{ md: 'sm', xl: 'md' }}
                        lineHeight="xs"
                      >
                        {translate(opsPhase.phase, opsPhase?.translations)}
                      </Text>
                      <View
                        px={{ xl: 1 }}
                        ml={{ xl: 1 }}
                        borderWidth={{ xl: 1 }}
                        borderRadius={4}
                        borderColor={isActive ? 'black' : 'gray.300'}
                        bg={{ xl: isActive ? 'black' : 'gray.200' }}
                      >
                        <Text
                          size="xs"
                          fontWeight={600}
                          color={{ xl: isActive ? 'white' : 'gray.500', base: isActive ? 'gray.600' : 'gray.500' }}
                        >
                          {formatToShortTimeOnly(startTime)}
                        </Text>
                      </View>
                    </View>
                  </ButtonGroup.Item>
                );
              })}
            </ButtonGroup>
          )}

          <HStack flex={1} space={3} alignItems="center" justifyContent="flex-end">
            {querySalesVolumeProfiles.isSuccess && !!queryFlowChartAgg.data?.flowChartData && (
              <SelectSalesVolumeProfiles
                opsPhaseTitle={queryFlowChartAgg.data?.flowChartData?.opsPhase?.phase}
                salesVolumeProfileId={queryFlowChartAgg.data?.flowChartData?.salesVolumeProfileId}
                salesVolumeProfiles={querySalesVolumeProfiles}
                onChange={modalConfirm.setOpen}
              />
            )}
          </HStack>
        </HStack>

        <View flex={1}>
          <ResourceLoader h="full" w="full" isLoading={queryFlowChartAgg.isIdle || queryFlowChartAgg.isLoading}>
            {!!siteConfig.id && (
              <FlowChartChecklistProvider opsPhaseId={opsPhaseFocusId} opsTaskFlowChartAgg={queryFlowChartAgg} siteId={siteConfig.id}>
                <FlowChartChecklist isDisabled={isDisabled} onAssign={modalPosition.setOpen} />
              </FlowChartChecklistProvider>
            )}
          </ResourceLoader>

          <OpsPhasePositioningProvider
            opsTasksPositioning={queryOpsTasksPositioning}
            salesVolumeProfiles={querySalesVolumeProfiles}
            selectedDate={startOfDay}
            siteId={siteConfig.id!}
          >
            <Modal
              size="full"
              _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
              isOpen={modalPosition.isOpen}
              noPadding
              hideClose
            >
              {!!modalPosition.payload && (
                <SelectEligableEmployee
                  assignee={modalPosition.payload.assignee}
                  opsPhaseId={activeOpsPhaseId}
                  positionId={modalPosition.payload.positionId}
                  salesVolumeProfileId={queryFlowChartAgg.data?.flowChartData?.salesVolumeProfileId!}
                  siteId={siteConfig.id}
                  startOfDay={startOfDay}
                  onClose={modalPosition.setHide}
                />
              )}
            </Modal>
          </OpsPhasePositioningProvider>
        </View>
      </VStack>

      <Modal
        hideClose
        isOpen={modalConfirm.isOpen}
        onClose={modalConfirm.setHide}
        size="md"
        _content={{ rounded: 'xl', p: 5, pt: 4, maxW: 400 }}
      >
        <SelectConfirmSalesVolumeProfile
          opsPhaseId={queryFlowChartAgg.data?.flowChartData?.opsPhaseId}
          salesVolumeProfileId={modalConfirm.payload}
          siteId={siteConfig.id}
          confirmRefetch={handleConfirmRefetch}
          onClose={modalConfirm.setHide}
        />
      </Modal>
    </MainContainer>
  );
};
