import { useEffect, useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, HStack, Spacer, VStack } from 'native-base';
import { compact, find, first, flatten, groupBy, isEmpty, isEqual, map, values } from 'lodash';

import { Button, ButtonGroup, ControlInput, FormHelperText, Text } from '@pimm/base';
import { GoalProfileDto, SiteGoalDto } from '@pimm/services/lib/sms-drivethru/types';
import { useSiteConfig } from '@app/features/store-core';
import { useQuerySettingsAndGoals } from '../context';
import { useSiteDailyGoals } from '../state';
import { FormSiteGoalItem, useFormSiteGoals } from '../hooks';
import { GoalMenuOption, PopoverSelectProfile } from './popover-select-profile';
import { SelectGoalProfile } from './select-goal-profile';
import { useAppLocale } from '@pimm/common';

type FormSiteGoalsProps = {
  form: ReturnType<typeof useFormSiteGoals>;
};

export const FormSiteGoals = ({ form }: FormSiteGoalsProps) => {
  const { translate } = useAppLocale();
  const { siteConfig } = useSiteConfig();
  const settingsAndGoals = useQuerySettingsAndGoals();
  const dailyGoals = useSiteDailyGoals(state => state.goals);
  const [numberFields] = useState<(keyof FormSiteGoalItem)[]>(['greet', 'menuBoard', 'pickup', 'total']);
  const [flatProfiles, setFlatProfiles] = useState<GoalProfileDto[]>([]);
  const [tabFocus, setTabFocus] = useState<'weekdays' | 'weekends'>('weekdays');
  const [options, setOptions] = useState<GoalMenuOption[]>([]);
  const formValues = useWatch({ control: form.control });

  const dayparts = siteConfig.config?.storeHoursConfig?.dayparts;
  const goals = formValues[tabFocus] ?? [];

  const handleChangeAllProfile = (_profileName?: string) => {
    const profiles =
      flatProfiles.filter(_ => !!_profileName && (_.name === _profileName || (_.customerId && _profileName === 'Franchise'))) ?? [];

    const inputs = goals.map(input => {
      const profile = profiles.find(_ => _.dpStartTime === input.dpStartTime);
      return {
        ...input,
        goalProfileId: profile?.id ?? 0,
        goalProfileName: profile?.name,
        greet: profile?.greet ?? input.greet,
        menuBoard: profile?.menuBoard ?? input.menuBoard,
        pickup: profile?.pickup ?? input.pickup,
        total: profile?.total ?? input.total,
      };
    });
    
    form.setValue(tabFocus, inputs, {
      shouldDirty: true,
    });
  };

  const handleChangeProfile = (index: number) => (value?: string) => {
    const input = goals?.[index];

    if (input) {
      const profile = flatProfiles?.find(_ => !!value && _.dpStartTime === input.dpStartTime && _.name === value);

      form.setValue(
        `${tabFocus}.${index}`,
        {
          ...input,
          goalProfileId: profile?.id ?? 0,
          goalProfileName: profile?.name,
          greet: profile?.greet ?? input.greet,
          menuBoard: profile?.menuBoard ?? input.menuBoard,
          pickup: profile?.pickup ?? input.pickup,
          total: profile?.total ?? input.total,
        },
        {
          shouldDirty: true,
        },
      );
    }
  };

  const handleFormReset = () => {
    if (dailyGoals && !isEmpty(dailyGoals[1])) {
      // Set the given site goals and corresponding daypart name
      // 1 = Mon (Weekdays) 0 = Sun (Weekends)
      const [weekends, weekdays] = [0, 1].map(day => {
        return map(dayparts, _daypart => {
          const goal: SiteGoalDto = find(dailyGoals[day], ['dpStartTime', _daypart.startTime]) ?? {
            dpStartTime: _daypart.startTime,
            goalProfileId: 0,
            goalProfileName: 'Custom',
          };

          const formItem: FormSiteGoalItem = {
            label: _daypart?.label,
            partNumber: _daypart?.partNumber,
            dpStartTime: _daypart?.startTime,
            ...goal,
          };

          return formItem;
        });
      });

      form.reset({
        isSevenDays: isEqual(
          weekdays.map(_ => _.goalProfileName),
          weekends.map(_ => _.goalProfileName),
        ),
        weekdays,
        weekends,
      });
    }
  };

  useEffect(() => handleFormReset(), [dailyGoals]);

  useEffect(() => setTabFocus('weekdays'), [formValues.isSevenDays]);

  useEffect(() => {
    // Create a full list of GoalProfileDto
    setFlatProfiles(compact(flatten([settingsAndGoals.data?.brandGoalProfiles, settingsAndGoals.data?.customerGoalProfiles])));

    const brandGoals = groupBy(settingsAndGoals.data?.brandGoalProfiles, 'name');
    const customerGoal = first(settingsAndGoals.data?.customerGoalProfiles);
    const _goals: GoalMenuOption[] = [];

    values(brandGoals).forEach(_arr => {
      if (_arr[0]) _goals.push({ label: 'Brand', goal: _arr[0] });
    });
    if (customerGoal) _goals.push({ label: 'Customer', goal: customerGoal, textColor: 'gray.600' });
    _goals.push({ label: 'Custom Goal', caption: 'Goals for each Daypart will be set manually' });
    setOptions(_goals);
  }, [settingsAndGoals.data]);

  return (
    <VStack pb={3}>
      <HStack space={2} alignItems="center" py={2}>
        {!formValues.isSevenDays && (
          <ButtonGroup value={tabFocus} onChange={setTabFocus}>
            {['Weekdays', 'Weekends'].map(tab => (
              <ButtonGroup.Item key={tab} value={tab.toLowerCase()}>
                {tab}
              </ButtonGroup.Item>
            ))}
          </ButtonGroup>
        )}

        <Spacer />

        <HStack space={2}>
          <SelectGoalProfile
            options={options}
            onChange={handleChangeAllProfile}
            trigger={triggerProps => (
              <Button variant="unstyled" {...triggerProps}>
                Apply Profile to all
              </Button>
            )}
          />

          <Button variant="unstyled" onPress={handleFormReset}>
            Reset
          </Button>
        </HStack>
      </HStack>

      <VStack rounded="xl" borderWidth={1}>
        {/* Table Header */}
        <HStack roundedTop="xl" overflowY="hidden" height={10} backgroundColor="gray.25" borderBottomWidth={1}>
          <Box w="120px"></Box>
          <Box flex={1} alignItems="center" justifyContent="center" h="full" maxW="190px" borderLeftWidth={1}>
            <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
              Goal Profile
            </Text>
          </Box>
          <HStack flex={1} h="full">
            <Box alignItems="center" justifyContent="center" h="full" w="1/4" borderLeftWidth={1}>
              <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                Greet
              </Text>
            </Box>
            <Box alignItems="center" justifyContent="center" h="full" w="1/4" borderLeftWidth={1}>
              <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                Menu Board
              </Text>
            </Box>
            <Box alignItems="center" justifyContent="center" h="full" w="1/4" borderLeftWidth={1}>
              <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                Pickup
              </Text>
            </Box>
            <Box alignItems="center" justifyContent="center" h="full" w="1/4" borderLeftWidth={1}>
              <Text size="sm" fontWeight={500} color="gray.600" lineHeight="xs" textAlign="center">
                Total
              </Text>
            </Box>
          </HStack>
        </HStack>

        {/* Table Body */}
        {map(dayparts, (_daypart, index) => {
          const isLast = index === dayparts!.length - 1;
          return (
            <HStack
              key={`${tabFocus}.${_daypart.partNumber}`}
              roundedBottom={isLast ? 'xl' : undefined}
              height="56px"
              borderTopWidth={index ? 1 : 0}
              bg={index % 2 === 0 ? 'white' : 'gray.50'}
            >
              <Box justifyContent="center" w="120px">
                <Text size="md" fontWeight={500} color="black" lineHeight="xs" textAlign="center">
                  {translate(_daypart.label, _daypart.translations)}
                </Text>
              </Box>

              <Box flex={1} alignItems="center" justifyContent="center" h="full" maxW="190px" borderLeftWidth={1}>
                <Box w="90%">
                  <PopoverSelectProfile options={options} value={goals[index]?.goalProfileName} onChange={handleChangeProfile(index)} />
                </Box>
              </Box>

              <HStack flex={1} h="full">
                {numberFields.map(name => {
                  return (
                    <Box
                      key={`${tabFocus}.${_daypart.partNumber}.${name}`}
                      alignItems="center"
                      justifyContent="center"
                      h="full"
                      w="1/4"
                      borderLeftWidth={1}
                    >
                      {goals[index]?.goalProfileId === 0 ? (
                        <ControlInput
                          _input={{ maxW: 70, textAlign: 'center' }}
                          control={form.control}
                          name={`${tabFocus}.${index}.${name}`}
                          numericOnly
                        />
                      ) : (
                        <Text size="md" fontWeight={500} color="black" lineHeight="xs" textAlign="center">
                          {goals[index]?.[name] ?? 0}
                        </Text>
                      )}
                    </Box>
                  );
                })}
              </HStack>
            </HStack>
          );
        })}
      </VStack>

      {!isEmpty(form.formState.errors) && (
        <Box pt={2}>
          <FormHelperText visible error>
            Please make sure to fill out all fields above before submitting
          </FormHelperText>
        </Box>
      )}
    </VStack>
  );
};
