import { useMutation } from 'react-query';
import { Box, FlatList, useBreakpointValue, VStack } from 'native-base';
import { useTranslation } from 'react-i18next';

import { Button, Modal, ZapIcon } from '@pimm/base';
import { formatToISOString } from '@app/utils/date-formatter';
import { ShiftDto, SmsWorkforceApi } from '@pimm/services/lib/sms-workforce';
import { useModalFocus } from '@app/hooks/modal-focus.hook';
import { useOpsPhasePositioning } from '../context';
import { useMutateAutoAssignOpsPositioning } from '../hooks';
import { OpsPhasePositionAssignment } from '../reducers/ops-phase-positioning.reducer';
import { OpsPhasePositions } from './ops-phase-positions';
import { SelectEligableEmployee } from './select-eligable-employee';

export interface ModalPositionProps extends OpsPhasePositionAssignment {
  opsPhaseId: number;
  salesVolumeProfileId: number;
  employees?: ShiftDto[];
}

type OpsPhasesPositioningProps = {
  isDisabled?: boolean;
  selectedDate?: Date;
};

export const OpsPhasesPositioning = ({ selectedDate, isDisabled }: OpsPhasesPositioningProps) => {
  const { t } = useTranslation();
  const numColumns = useBreakpointValue({ sm: 2, xl: 3 });
  const { opsTasksPositioning, positioning, siteId } = useOpsPhasePositioning();
  const modalPosition = useModalFocus<ModalPositionProps>();

  const autoAssignOpsTaskAssignment = useMutation({ mutationFn: SmsWorkforceApi.AutoAssignOpsTaskAssignment });
  const autoAssignOpsPositioning = useMutateAutoAssignOpsPositioning();

  const handleAutoAssign = async () => {
    if (siteId && selectedDate) {
      await autoAssignOpsPositioning.mutateAsync({ date: selectedDate, siteId: siteId });
    }
  };

  const handlePressSelect = (position: ModalPositionProps) => () => {
    modalPosition.setOpen(position);
  };

  return (
    <VStack flex={1}>
      {!isDisabled && (
        <Box px={4} minH="60px" borderBottomWidth={1} alignItems="flex-end" justifyContent="center">
          <Button
            accessibilityLabel="Reset and Auto Assign Employee"
            w="140px"
            isLoading={autoAssignOpsTaskAssignment.isLoading || opsTasksPositioning.isFetching}
            onPress={handleAutoAssign}
            startIcon={<ZapIcon size="18px" color="black" />}
          >
            {t('common:auto_assign')}
          </Button>
        </Box>
      )}

      <FlatList
        p={2}
        h="full"
        w="full"
        data={positioning.opsPhases}
        extraData={[numColumns]}
        numColumns={numColumns}
        renderItem={({ item: opsPhase }) => (
          <Box p={1} w={`1/${numColumns}`}>
            <Box>
              <OpsPhasePositions opsPhase={opsPhase} isDisabled={isDisabled} onPressSelect={position => handlePressSelect(position)()} />
            </Box>
          </Box>
        )}
      />

      {!isDisabled && (
        <Modal
          size="full"
          _content={{ rounded: 'xl', maxWidth: { md: 1080, xl: 1200 }, height: '95%', maxHeight: 900 }}
          isOpen={modalPosition.isOpen}
          noPadding
          hideClose
        >
          {!!modalPosition.payload && (
            <SelectEligableEmployee
              assignee={modalPosition.payload.assignee}
              opsPhaseId={modalPosition.payload?.opsPhaseId}
              positionId={modalPosition.payload.id}
              salesVolumeProfileId={modalPosition.payload.salesVolumeProfileId!}
              siteId={siteId}
              startOfDay={modalPosition.payload.startTime}
              onClose={modalPosition.setHide}
            />
          )}
        </Modal>
      )}
    </VStack>
  );
};
