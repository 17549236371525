import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { RecursivePartial } from '@pimm/base';
import { SiteGoalDto } from '@pimm/services/lib/sms-drivethru/types';

export type FormSiteGoalItem = Omit<SiteGoalDto, 'dayOfWeek'> & {
  label?: string;
  partNumber?: number;
};

export interface FormDataSiteGoals {
  isSevenDays: boolean;
  weekdays: FormSiteGoalItem[];
  weekends: FormSiteGoalItem[];
}

export const FormSiteGoalItemSchema = yup.object().shape({
  label: yup.string(),
  partNumber: yup.number().optional(),
  dpStartTime: yup.string().optional(),
  goalProfileId: yup.number().optional(),
  goalProfileName: yup.string().optional(),
  greet: yup.number().optional(),
  id: yup.number().optional(),
  menuBoard: yup.number().optional(),
  pickup: yup.number().optional(),
  siteId: yup.string().optional(),
  total: yup.number().optional(),
});

export const FormSiteGoalsSchema = yup
  .object({
    isSevenDays: yup.boolean().default(true),
    weekdays: yup.array().of(FormSiteGoalItemSchema).default([]),
    weekends: yup.array().of(FormSiteGoalItemSchema).default([]),
  })
  .test({
    name: 'must-have-values',
    message: 'Please make sure to fill out all fields above before submitting',
    test: fields => {
      let goals = fields.weekdays as FormSiteGoalItem[];
      if (!fields.isSevenDays) goals = [...goals, ...fields.weekends];
      const missingValues = goals.find(_goal => {
        return ['greet', 'menuBoard', 'pickup', 'total'].some(_field => !_goal[_field] || isNaN(_goal[_field]));
      });
      return !missingValues;
    },
  });

export type FormSiteGoalsProps = {
  defaultValues?: RecursivePartial<FormDataSiteGoals>;
};

export const useFormSiteGoals = (props?: FormSiteGoalsProps) => {
  return useForm<FormDataSiteGoals>({
    defaultValues: { ...FormSiteGoalsSchema.getDefault(), ...props?.defaultValues },
    resolver: yupResolver(FormSiteGoalsSchema),
  });
};
