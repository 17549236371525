import React, { useState, useMemo } from 'react';
import { HStack, VStack, Box, View } from 'native-base';
import { chain, indexOf, keys, map, get } from 'lodash';
import FeatherIcons from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { ButtonGroup, Text } from '@pimm/base';
import { SiteConfigDto } from '@pimm/services/lib/sms-tenants';
import { EmployeeStatusEnum } from '@pimm/services/lib/sms-workforce';
import VirtualTable, { VirtualColumnProps } from '@app/components/virtual-table/virtual-table';
import { ResourceLoader } from '@app/components/shared';
import { formatTo } from '@app/utils/date-formatter';
import { SnoozeClock } from './icons';
import { useGetEmployees } from './hooks/get-employees.hook';

interface EmployeeItem {
  userRole?: string;
  firstName: string;
  lastName: string;
  title?: string;
  employeeNum?: string;
  mobileNumber?: string;
  email?: string;
  status?: string;
  birthDate?: string;
  hireDate?: string;
  lockMode?: string;
}

interface StoreMemoData {
  tableData: EmployeeItem[];
  counter?: {
    [key in EmployeeStatusEnum]: number;
  };
}

type StoreRosterProps = {
  queryEmployees: ReturnType<typeof useGetEmployees>[0];
  store?: SiteConfigDto;
};

export const StoreRosters = ({ queryEmployees }: StoreRosterProps) => {
  const [employeeStatus, setEmployeeStatus] = useState<EmployeeStatusEnum>(EmployeeStatusEnum.Active);
  // By using  useState, React will keep the same reference for value until you update it, this is to prevent rerendering of VirtualTable
  const [tableColumns, setTableColumns] = useState<VirtualColumnProps<EmployeeItem>[]>([
    /**TODO: Will hide for the mean time
     *  { dataField: 'userRole', name: 'User Role' },*/
    { dataField: 'firstName', name: 'First Name' },
    { dataField: 'lastName', name: 'Last Name' },
    { dataField: 'title', name: 'Job Title' },
    { dataField: 'employeeNum', name: 'Employee ID' },
    { dataField: 'email', name: 'Email' },
    { dataField: 'mobileNumber', name: 'Mobile Phone' },
    { dataField: 'birthDate', name: 'Birth date' },
    { dataField: 'hireDate', name: 'Hire Date' },
    { dataField: 'lockMode', name: 'BOH Lock Mode' },
  ]);
  const tabElements: Record<EmployeeStatusEnum, React.ReactNode> = {
    [EmployeeStatusEnum.Active]: <MaterialCommunityIcons name="check-circle-outline" />,
    [EmployeeStatusEnum.Inactive]: <SnoozeClock fill="none" />,
    [EmployeeStatusEnum.Terminated]: <FeatherIcons name="x-circle" />,
  };

  const { counter, tableData }: StoreMemoData = useMemo(() => {
    let tableData: EmployeeItem[] = [];
    let counter;

    // Distinct employee and it's status
    counter = chain(queryEmployees.data)
      .countBy(item => item.status)
      .value();
    // Creating schema for displaying a list of employees
    tableData = chain(queryEmployees.data)
      .filter(({ status }) => status === employeeStatus)
      .orderBy([
        ({ role }) => {
          const index = indexOf(['sms manager', 'sms user', 'sms employee'], role?.toLowerCase() || '', 0);
          return index === -1 ? 4 : index;
        },
        'firstName',
        'lastName',
      ])
      .map(employee => ({
        /**TODO: Will hide for the mean time
         * userRole: employee.role ?? '', */
        firstName: employee.firstName ?? '',
        lastName: employee.lastName ?? '',
        title: employee.title,
        employeeNum: employee.employeeNum ?? '',
        mobileNumber: employee.mobileNumber ?? '',
        email: employee.email ?? '',
        status: employee.status ?? '',
        birthDate: formatTo(employee.birthDate, 'MM/DD') ?? '',
        hireDate: formatTo(employee.hireDate, 'MM/DD') ?? '',
        lockMode: employee?.isLocked ? 'Enabled' : '',
      }))
      .value();

    return { counter, tableData };
  }, [employeeStatus, queryEmployees?.data]);

  const handleChangeTab = (value: EmployeeStatusEnum) => {
    setEmployeeStatus(value);
  };

  return (
    <VStack h="full" w="full" py={1} px={4} flex={1}>
      <HStack pb={2} justifyContent="space-between" alignItems="center" space={2}>
        <Text size="xl" color="black" fontWeight={600}>
          Store Roster
        </Text>
        {/* Store Roster Tab Selection */}
        <ButtonGroup value={employeeStatus} onChange={value => setEmployeeStatus(value as EmployeeStatusEnum)}>
          {map(keys(tabElements), status => {
            return (
              <ButtonGroup.Item key={status} value={status} badgeIcon={{ show: true, value: get(counter, status) }}>
                {status}
              </ButtonGroup.Item>
            );
          })}
        </ButtonGroup>
      </HStack>

      <View w="full" flex={1}>
        <Box maxHeight="full">
          <VirtualTable
            bordered
            columns={tableColumns}
            data={tableData}
            renderEmptyData={() => {
              const isLoading = queryEmployees.isFetching;
              return <ResourceLoader isEmpty isLoading={isLoading} minHeight={100} />;
            }}
          />
        </Box>
      </View>
    </VStack>
  );
};
